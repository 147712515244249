<template>
  <router-view />
</template>

<script setup>
defineOptions({
  name: "App",
});
import { useAppUtils } from "@/composables/useAppUtils";
const { setActiveTheme, setActiveLanguage, applyPrimaryColor } = useAppUtils();
setActiveTheme();
applyPrimaryColor();
setActiveLanguage();
</script>
