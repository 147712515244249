

export default {
  "app": {
    "verified": "Verifiziert",
    "not-verified": "Nicht verifiziert",
    "verify": "Verifizieren",
    "change": "Ändern",
    "finish": "Fertig",
    "info-tooltip": "Info",
    "footer-dekra-title": "DEKRA Arbeit Group",
    "footer-part-of": ", Teil von",
    "organization": "Organisation",
    "theme": "App-Thema",
    "cancel": "Abbrechen",
    "crop-and-save": "Zuschneiden und Speichern",
    "confirm": "Bestätigen",
    "org-settings": "Org. Einstellungen",
    "invite-user": "Benutzer einladen",
    "profile": "Profil",
    "settings": "Einstellungen",
    "logout": "Abmelden",
    "search": "Suche",
    "sort-by": "Sortieren nach",
    "ascending": "Aufsteigend",
    "ascending-short": "Asc",
    "descending": "Absteigend",
    "descending-short": "Desc",
    "filter-btn": "Anwenden",
    "reset-filter": "Filter zurücksetzen",
    "info": "Info",
    "not-this-user": "Nicht dieser Benutzer?",
    "continue": "Weiter",
    "enter-code": "Gib den Code ein",
    "clear": "Löschen",
    "save": "Speichern",
    "add-new": "Neu hinzufügen",
    "no-data": "Keine Daten verfügbar",
    "edit": "Bearbeiten",
    "delete": "Löschen",
    "include-deleted": "Gelöschte einbeziehen",
    "back": "Zurück",
    "loading": "Laden...",
    "collapse": "Einklappen",
    "send": "Senden",
    "version": "Anwendungsversion",
    "power-mode-btn": "Energiesparmodus",
    "go-back": "Zurück gehen",
    "my-account": "Mein Konto",
    "fullscreen": "Vollbild",
    "grid-label": "Grid",
    "table-label": "Tabelle",
    "grid-tooltip": "Grid-Anzeige",
    "table-tooltip": "Tabellenansicht",
    "export": "Als CSV exportieren",
    "work-in-progress": "In Arbeit"
  },
  "auth": {
    "login": "Anmelden",
    "submit": "Einreichen",
    "back-to-login": "Zurück zur Anmeldung",
    "forgot-password": "Passwort vergessen",
    "reset-password": "Passwort zurücksetzen",
    "reset-password-expired": "Passwort zurücksetzen abgelaufen",
    "set-password": "Passwort festlegen",
    "set-password-expired": "Passwort festlegen abgelaufen",
  },
  "nav-bar": {
    "home-title": "Startseite",
    "home-caption": "App-Dashboard",
    "users-manager-title": "Benutzerverwaltung",
    "users-manager-caption": "Benutzer in Organisation verwalten",
    "organizations-manager-title": "Organisation verwaltung",
    "organizations-manager-caption": "Organisationen verwalten",
    "users-title": "Benutzer",
    "users-caption": "Benutzerliste",
    "permissions-title": "Berechtigungen",
    "permissions-caption": "Berechtigungen",
    "roles-title": "Rollen",
    "roles-caption": "Rollen",
    "organizations-title": "Organisationen",
    "organizations-caption": "Organisationen",
    "settings-title": "Einstellungen",
    "settings-caption": "Account-Einstellungen",
    "collapse-title": "Einklappen",
    "collapse-caption": "Seitenleiste einklappen",
    "power-mode-title": "CYJ app",
    "power-mode-caption": "Main application"
  },
  "app-fields": {
    "first-name-label": "Vorname",
    "last-name-label": "Nachname",
    "username-label": "Benutzername",
    "email-label": "E-Mail",
    "phone-label": "Telefonnummer",
    "bio-label": "Biografie (Schreiben Sie eine kurze Einführung)",
    "organization-label": "Organisation",
    "admin-label": "Administrator",
  },
  "delete-modal": {
    "confirm-delete": "Löschen bestätigen",
    "delete-text": "Sind Sie sicher, dass Sie dieses Element löschen möchten?",
    "confirm-restore": "Wiederherstellen bestätigen",
    "restore-text": "Sind Sie sicher, dass Sie dieses Element wiederherstellen möchten?"
  },
  "welcome-page": {
    "welcome-title": "Willkommen bei",
    "powered-by": "Bereitgestellt von"
  },
  "users": {
    "invite-user": "Benutzer einladen",
    "users-list-title": "Benutzer in Organisation",
    "users-list": "Benutzer",
    "invite-user-title": "Benutzer in Organisation einladen",
    "filters": "Filter",
    "invite-user-page": {
      "email-info": "Geben Sie die E-Mail-Adresse der Person ein, die Sie einladen möchten",
      "username-info": "Geben Sie einen Benutzernamen für diese Person an",
      "roles": "Rollen zuweisen",
      "roles-info": "Wählen Sie die Rollen aus, die am besten den Aufgaben des Benutzers in dieser Organisation entsprechen.",
      "organization": "Organisation",
      "organization-info": "Wählen Sie die Organisation aus, zu der Sie den Benutzer einladen möchten.",
      "not-valid-email": "E-Mail ist nicht gültig",
      "not-valid-phone": "Telefonnummer ist nicht gültig",
      "not-valid-username": "Der Benutzername ist nicht gültig. Bitte verwenden Sie nur Kleinbuchstaben, Zahlen und Sonderzeichen.",
      "not-valid-admin-or-organization": "Wählen Sie eine Organisation und mindestens eine Rolle aus.",
      "user-in-org": "Dieser Benutzer ist bereits Mitglied der Organisation.",
      "success-page": "Sie haben den Benutzer erfolgreich zu dieser Organisation eingeladen."
    },
    "loading-users": "Benutzer werden geladen...",
    "admin-title": "Administrator",
    "remove-user-from-org": "Benutzer aus Organisation entfernen",
    "resend-invitation": "Einladung erneut senden",
    "invitation-accepted": "Einladung angenommen",
    "invitation-declined": "Einladung abgelehnt",
    "invitation-pending": "Einladung ausstehend",
    "edit-user": "Benutzer bearbeiten",
    "user-profile": "Benutzerprofil",
    "edit": {
      "email-label": "Benutzer-E-Mail",
      "username-label": "Benutzer-Benutzername"
    },
    "delete-user": "Benutzer löschen",
    "restore-user": "Benutzer wiederherstellen",
    "last-updated": "Zuletzt aktualisiert"
  },

  "organizations": {
    "create-organization": "Organisation erstellen",
    "organizations-list-title": "Organisationen",
    "organizations-list": "Organisationen",
    "organizations-all-invitations": "Alle Einladungen",
    "filters": "Filters",
    "loading-organizations": "Organisationen werden geladen...",
    "admin-title": "Administrator",
    "delete-organization": "Organisation löschen",
    "restore-organization": "Organisation wiederherstellen",
    "edit-organization": "Organisation bearbeiten",
    "organization-profile": "Organisationsprofil",
    "last-updated": "Zuletzt aktualisiert",
    "info-title": "Einladung zu",
    "tab-details-title": "Details",
    "tab-users-title": "Benutzer",
    "info-subtitle": "Sie wurden eingeladen, sich einer",
    "info-inviter-label": "Einladung gesendet von:",
    "info-roles-assigned-label": "Zugewiesene Rollen:",
    "info-roles-assigned-subtitle": "zugewiesene Rollen"
  },
  "roles": {
    "create-role": "Rolle erstellen",
    "roles-list-title": "Alle Rollen",
    "roles-list": "Rolle",
    "filters": "Filters",
    "loading-roles": "Rollen werden geladen...",
    "delete-role": "Rolle löschen",
    "restore-role": "Rolle wiederherstellen",
    "edit-role": "Rolle bearbeiten",
    "role-profile": "Rollenprofil",
    "last-updated": "Zuletzt aktualisiert",
    "info-permissions-assigned-label": "Zugewiesene Berechtigungen:",
  },
  "permissions": {
    "create-permission": "Berechtigung erstellen",
    "permissions-list-title": "Alle Berechtigungen",
    "permissions-list": "Berechtigungen",
    "filters": "Filters",
    "loading-permissions": "Berechtigungen werden geladen...",
    "delete-permission": "Berechtigung löschen",
    "edit-permission": "Berechtigung bearbeiten",
    "permission-profile": "Berechtigungsprofil",
    "last-updated": "Zuletzt aktualisiert"
  },
  "profile": {
    "reset-password-title": "Passwort zurücksetzen",
    "reset-password-info": "Letzte Passwortänderung um ",
    "reset-password-btn": "Passwort zurücksetzen",
    "reset-password-label-new-password": "Neues Pass",
    "reset-password-label-confirm-password": "Passwort bestätigen",
    "reset-password-new-password-not-valid": "Das Passwortfeld ist erforderlich und muss mindestens 8 Zeichen lang sein.",
    "reset-password-new-password-confirmation-not-valid": "Das Passwortbestätigungsfeld ist erforderlich und muss mit dem Passwortfeld übereinstimmen.",
    "reset-password-error": "Überprüfen Sie Ihre Telefonnummer, um das Passwort zu ändern",
    "reset-password-form-title": "Neues Passwort erstellen",
    "reset-password-form-subtitle": "Setzen Sie ein starkes Passwort, um Ihr Konto sicher zu halten.",
    "reset-password-hint": "Muss mindestens 8 Zeichen lang sein, es muss auch mindestens einen Großbuchstaben, einen Kleinbuchstaben, eine Zahl und ein Sonderzeichen enthalten.",
    "reset-password-succes-title": "Erfolgreiche Passwortänderung!",
    "reset-password-succes-info": "Sie können sich jetzt mit Ihrem neuen Passwort in Ihr Konto einloggen.",
    "reset-password-save-btn": "Speichern",
    "first-name-not-valid": "Vorname ist nicht gültig",
    "last-name-not-valid": "Nachname ist nicht gültig",
    "organization-tab-org-switch-title": "Organisation wechseln",
    "organization-tab-org-switch-tooltip": "Zu dieser Organisation wechseln",
    "organization-tab-org-leave-tooltip": "Diese Organisation verlassen",
    "organization-invitation-accept": "Akzeptieren",
    "organization-invitation-decline": "Ablehnen",
    "organization-tab-current-org": "Aktuelle Organisation",
    "tab-details-title": "Details",
    "tab-security-title": "Sicherheit",
    "tab-organizations-title": "Organisationen",
    "header-updated-at": "Aktualisiert am:",
    "add-phone-number": "Telefonnummer hinzufügen",
    "add-email": "E-Mail hinzufügen",
    "roles-label": "Rollen"
  },
  "otp-page": {
    "otp-title": "Einmalige Code-Authentifizierung",
    "otp-subtitle": "Ein Bestätigungscode wurde gesendet an ",
    "keycloak-otp-subtitle": "Öffnen Sie eine der Mobile Authenticator-Apps, finden Sie Ihr Konto und geben Sie den Einmalcode ein, der von der Anwendung bereitgestellt wird.",
    "otp-info": "Bitte geben Sie den 6-stelligen Code ein.",
    "otp-remaining-time": "Verbleibende Zeit: ",
    "otp-verify-btn": "Verifizieren",
    "otp-verify-problems-info": "Haben Sie Probleme beim Verifizieren Ihrer Telefonnummer?",
    "otp-resend-code-text": "Bestätigungscode erneut senden",
    "verification-code-label": "Einmaliger Code",
    "cooldown-title": "Abkühlphase...",
    "resend-cooldown-info": "Warten auf Abkühlphase..."
  },
  "helpers": {
    "back-home-btn": "Zurück zur Startseite",
    "no-org-page-title": "Sie sind kein Mitglied einer Organisation.",
    "message-field-label": "Senden Sie uns eine Nachricht, wenn Sie etwas brauchen.",
    "message-placeholder": "Nachricht",
    "no-permistions-page-title": "Zugriff verweigert!",
    "no-permissions-page-subtitle": "Sie haben nicht die Berechtigungen, diese Seite anzuzeigen. Kontaktieren Sie den Administrator für weitere Informationen!",
    "success-action": "Sie haben die Aktion erfolgreich abgeschlossen.",
    "error-500-title": "Diese Seite funktioniert nicht",
    "error-500": "Fehler 500",
    "error-404": "Fehler 404",
    "error-401": "Fehler 401",
    "error-503": "Fehler 503",
    "error-503-title": "Dienst nicht verfügbar",
    "error-503-subtitle": "Entschuldigung, die Seite ist vorübergehend nicht verfügbar.",
    "error-500-subtitle": "Wir entschuldigen uns und arbeiten an dem Problem. Bitte versuchen Sie es später erneut.",
    "error-404-title": "Etwas ist schiefgelaufen",
    "error-404-subtitle": "Entschuldigung, wir konnten diese Seite nicht finden."
  },
  "settings": {
    "general-title": "Allgemein",
    "appearance-title": "Erscheinungsbild",
    "theme-title": "Thema auswählen",
    "theme-subtitle": "Anpassung gemäß Ihren Vorlieben.",
    "theme-toggle": "Thema umschalten",
    "theme-hint": "Wählen Sie das Thema aus, das Sie verwenden möchten.",
    "grid-title": "Wählen Sie Ihren Anzeigestil",
    "grid-subtitle": "Anpassung nach Ihren Vorlieben.",
    "grid-toggle": "Wechseln Sie die Anzeige zwischen Gitter/Tabelle",
    "grid-hint": "Wählen Sie den Anzeigestil, den Sie verwenden möchten.",
    "primary-color-title": "Primärfarbe",
    "primary-color-subtitle": "Wählen Sie Ihre primäre Farbe aus.",
    "primary-color-hint": "Die ausgew ählte Farbe wird in der gesamten App angezeigt.",
    "date-format-title": "Datumsformat",
    "date-format-subtitle": "Wählen Sie das Datumsformat aus, das Sie verwenden möchten.",
    "date-format-hint": "Das ausgewählte Datumsformat wird in der gesamten App angezeigt.",
    "time-format-title": "Zeitformat",
    "time-format-subtitle": "Wählen Sie das Zeitformat aus, das Sie verwenden möchten.",
    "time-format-hint": "Das ausgewählte Zeitformat wird in der gesamten App angezeigt.",
    "notification-title": "Benachrichtigung",
    "email-notification-title": "E-Mail-Benachrichtigung",
    "email-notification-subtitle": "Substance kann Ihnen E-Mail-Benachrichtigungen für neue Direktnachrichten senden",
    "chat-notification-title": "Chat-Benachrichtigung",
    "chat-notification-subtitle": "Nachrichten, Erwähnungen und Reaktionen.",
    "show-preview-message": "Nachrichtenvorschau anzeigen",
    "play-sound-notification": "Ton für eingehende Benachrichtigungen abspielen",
    "templates-title": "Vorlagen",
    "language-title": "Sprache",
    "language-subtitle": "Verwalten Sie Ihre Spracheinstellungen",
    "language-hint": "Wählen Sie die Sprache aus, die Sie verwenden möchten.",
    "time-zone-title": "Zeitzone",
    "time-zone-subtitle": "Verwalten Sie Ihre Zeitzone",
    "time-zone-hint": "Wählen Sie Ihre Zeitzone aus."
  },
  "home": {
    "welcome-title": "Willkommen zu {org}, {city}",
    "welcome-subtitle": "Lass uns mit einer Tour durch dein Dashboard beginnen. Entdecke, wie du das Beste aus deinem Arbeitsbereich machen kannst.",
    "how-to": "Wie man?",
    "send-msg-btn": "Nachricht senden",
    "create-page-btn": "Seite erstellen",
    "import-contacts-btn": "Kontakte importieren",
    "search-contacts-btn": "Kontakte suchen",
    "complete-your-profile": "Vervollständige dein Profil",
    "complete-your-profile-text": "Profilinformationen aktualisieren",
    "verify-phone-number": "Telefonnummer bestätigen",
    "verify-email": "Bestätige deine E-Mail",
    "add-user-avatar": "Benutzer-Avatar hinzufügen",
    "update-your-bio": "Aktualisiere deine Biografie",
    "add-first-name": "Vorname hinzufügen",
    "add-last-name": "Nachname hinzufügen",
    "action-box-title": "Aktionsfeld",
    "action-box-pending": "Ausstehende Einladungen",
    "action-box-invited-by": "Eingeladen von ",
    "insights-title": "Einblicke",
    "insights-add": "Einblick hinzufügen",
    "teammates-title": "Teammitglieder",
    "teammates-subtitle": "Laden Sie Ihre Teammitglieder ein",
    "teammates-invite-button": "Einladen",
    "teammates-list-button": "Konten verwalten"
  },
  "table-columns": {
    "id": "ID",
    "first-name": "Vorname",
    "last-name": "Nachname",
    "username": "Benutzername",
    "bio": "Biografie",
    "name": "Name",
    "display-name": "Anzeigename",
    "description": "Beschreibung",
    "email": "E-Mail",
    "city": "Stadt",
    "api-prefix": "API Präfix",
    "website": "Webseite",
    "phone-number": "Telefonnummer",
    "address": "Adresse",
    "phone-number-verified-at": "Telefonnummer verifiziert am",
    "permissions": "Berechtigungen",
    "created-at": "Erstellt am",
    "updated-at": "Aktualisiert am",
    "actions": "Aktionen"
  }
}
