import { defineStore } from 'pinia'
import { api } from 'boot/axios'
import { i18n } from 'boot/i18n';
import { useAppUtils } from "@/composables/useAppUtils";
import { LocalStorage, Notify } from 'quasar'

export const useOrganizationStore = defineStore('organization', {
  id: 'Organization',
  state: () => ({
    myOrganization: LocalStorage.getItem('myOrganization') || null,
    items: [],
    item: {},
    allItems: [],
    loading: false,
    pagination: {
      page: 1,
      deleted: false,
      orderDir: "desc",
      search: "",
      orderBy: "id",
      rowsPerPage: 12,
      rowsNumber: 1,
    },
  }),

  getters: {
    tableColumns: () => {
      const { t } = i18n
      const { formatDate } = useAppUtils();
      return [
        { name: 'id', label: t('table-columns.id'), align: 'left', field: 'id', },
        { name: 'name', label: t('table-columns.name'), align: 'center', field: 'name', format: (row) => row || 'N/A' },
        { name: 'api_prefix', label: t('table-columns.api-prefix'), field: 'api_prefix', format: (row) => row || 'N/A' },
        { name: 'email', label: t('table-columns.email'), field: 'email', format: (row) => row || 'N/A' },
        { name: 'phone_number', label: t('table-columns.phone-number'), field: 'phone_number', format: (row) => row || 'N/A' },
        { name: 'city', label: t('table-columns.city'), field: 'city', format: (row) => row || 'N/A' },
        { name: 'created_at', label: t('table-columns.created-at'), field: 'created_at', align: 'center', format: (row) => formatDate(row) },
        { name: 'actions', label: t('table-columns.actions'), field: '', align: 'right', }
      ]
    },
    filterColumns: () => {
      const { t } = i18n
      return [
        { label: t('table-columns.name'), value: "name" },
        { label: t('table-columns.email'), value: "email" },
        { label: t('table-columns.phone-number'), value: "phone_number" },
        { label: t('table-columns.city'), value: "city" },
        { label: t('table-columns.created-at'), value: "created_at" }
      ]
    },
    exportColumns: () => {
      const { t } = i18n
      return [
        { label: t('table-columns.name'), value: "name" },
        { label: t('table-columns.email'), value: "email" },
        { label: t('table-columns.phone-number'), value: "phone_number" },
        { label: t('table-columns.city'), value: "city" },
        { label: t('table-columns.created-at'), value: "created_at" }
      ]
    }
  },

  actions: {
    async getItems() {
      this.loading = true
      try {
        const { rowsPerPage, ...rest } = this.pagination
        const newPagin = { perPage: rowsPerPage, ...rest }
        const { data } = await api.get('/control/organizations', { params: { ...newPagin } })
        this.loading = false
        this.pagination.rowsNumber = data.meta.total
        this.pagination.rowsPerPage = data.meta.per_page
        this.pagination.page = data.meta.current_page
        this.items = data.data

      }
      catch (error) {
        this.loading = false
        throw error
      }
    },

    setMyOrganization(item) {
      const currentOrg = this.myOrganization
      if (currentOrg) return
      if (item?.user_organizations.length !== 0) {
        if (!this.myOrganization) {
          let firstAcceptedInvitation = item.user_organizations.find(
            ({ invitation_status }) => {
              return invitation_status === "Accepted";
            }
          );
          this.myOrganization = firstAcceptedInvitation || item.user_organizations[0];
          LocalStorage.set('myOrganization', this.myOrganization)
        }
      }
    },


    async getAllItems() {
      this.loading = true
      try {
        const { data } = await api.get('/control/organizations', { params: { all: true } })
        this.loading = false
        this.allItems = data
      }
      catch (error) {
        this.loading = false
        throw error
      }
    },

    async insertItem(org) {
      this.loading = true
      try {
        await api.post('/control/organizations/', org)
        this.loading = false
      }
      catch (error) {
        this.loading = false
        throw error
      }
    },

    async getItem(id) {
      this.loading = true
      try {
        const { data } = await api.get('/control/organizations/' + id)
        this.loading = false
        this.item = data
      }
      catch (error) {
        this.loading = false
        throw error
      }
    },

    async updateItem(org) {
      this.loading = true
      try {
        await api.put('/control/organizations/' + org.id, org.values)
        this.loading = false
      }
      catch (error) {
        this.loading = false
        throw error
      }
    },

    async deleteItem(org) {
      this.loading = true
      try {
        const { data } = await api.delete('/control/organizations/' + org.id)
        Notify.create({
          message: data.message,
          type: 'positive',
        })
        this.loading = false
        this.getItems()
      }
      catch (error) {
        this.loading = false
        throw error
      }
    },
  },
})
